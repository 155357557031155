$highlight-background   :#2d2d2d;
$highlight-foreground   :#ccc;
$highlight-comment      :#999;
$highlight-red          :#f2777a;
$highlight-orange       :#f99157;
$highlight-yellow       :#fc6;
$highlight-green        :#9c9;
$highlight-aqua         :#6cc;
$highlight-blue         :#69c;
$highlight-purple       :#c9c;
